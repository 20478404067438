<template>
  <div>
    <common-table

      ref="ct"
      :headers="headers"
      :listData="listData"
      @search="search"
      @remove="remove"
      @save="save"
      @edit="edit"
      :btnAction="true"
      :loading="loading"
      @changePage="changePage"
      :rightConfig="rightConfig"
      :leftConfig="leftConfig"
      :listDeploy="listDeploy"
      @beforeOpenAddDialog="beforeOpenAddDialog"
      pageTitle="一级栏目"
      :btnActionEvent="btnActionEvent"
      @otherTableOperate="otherTableOperate"
      pageKey="menuid"
      :itemScope="['mname','show']"
    >
      <template v-slot:mname="{ item }">
        <div
            @click="asv(item.mname)"
            :style="{
              color: 'var(--v-highlightBlue-base)',
              cursor: 'pointer',
            }"
          >
            {{ item.mname }}
          </div>
      </template>
      <template v-slot:show="{ item }">
        <w-switches
          inset
          label=""
          color="primary"
          @change="updateStatus(item.sqlid)"
          v-model="item.show"
          hide-details
          dense
        />
      </template>
    </common-table>
  </div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import form from "./column-manage-one-form.js";
import api from "@/api/column-manage-one/column-manage-one.js";
import commondata from "@/api/common-data.js";
export default {
  mixins: [form, commonCurd],
  props: {
    ks: {
      type: String,
      default: "lala",
    },
  },
  computed: {
    ko() {
      return 555;
    },
  },
  data() {
    return {
      api,
      // 定义右侧按钮
      rightConfig: [{ label: "新建", icon: "zengjia", event: "add" }],
      // 定义左侧搜索框
      leftConfig: [
        { type: "input", label: "输入栏目ID/名称", property: "searchkey" },
        {
          type: "select",
          label: "选择上级分类",
          property: "columntype",
          items: [],
        },
      ],
      fixedParams: {
        columnLv: "1",
        mtype: "M",
      },
      headers: [
        { text: "一级栏目ID", value: "menuid" },
        { text: "一级栏目名称", value: "mname" },
        { text: "上级分类", value: "columntype" },
        { text: "排序", value: "seq" },
        { text: "显示状态", value: "show" },
        { text: "操作", value: "actions", width: 120 },
      ],
      btnActionEvent: [
        {
          event: "editItem",
          icon: "bianji2-fill",
          color: "primary",
          tooltip: "编辑",
        },
        {
          event: "deleteItem",
          icon: "shanchu-fill",
          color: "var(--v-redness-base)",
          tooltip: "删除",
        },
        {
          event: "upMove",
          icon: "shengxu",
          color: "primary",
          tooltip: "上移",
        },
        {
          event: "downMove",
          icon: "jiangxu",
          color: "primary",
          tooltip: "下移",
        },
      ],
    };
  },
  methods: {
    // 直接在表格上点击修改状态
    updateStatus(sqlid) {
      this.loading = true;
      this.axios
        .post('/api/menus/updateStatus', { sqlid: sqlid })
        .then((r) => {
          this.loading = false;
          if (r.code === this.staticVal.Code.Success) {
            this.snackbar.success("更新成功！");
            this.list();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    asv(v) {
      this.confirm
        .showConfirm(`确定要跳转至${v}（栏目名称）内容吗`)
        .then(() => {
          this.$router.push({name: 'contentManage', query: {value: v,grade:1}});
        })
        .catch(() => {});
    },
    createdExtend() {
      this.axios
        .post(this.select_data, commondata.getSelectList({ keyname: "column" }))
        .then((res) => {
          if (res.code === this.staticVal.Code.Success) {
            commondata.assignment(this.itemsGroup, ["column"], res.data);
            this.leftConfig[1].items = this.itemsGroup.column;
          }
        });
    },
    beforeOpenAddDialog(o) {
      if (o === "add") {
        this.listDeploy.row[0].connector.props.disabled = false;
      } else {
        this.listDeploy.row[0].connector.props.disabled = true;
      }
      return this.axios
        .post(
          this.select_data,
          commondata.getSelectList({ keyname: "column,comptype,contentseq" })
        )
        .then((res) => {
          commondata.assignment(
            this.itemsGroup,
            ["column", "comptype", "contentseq"],
            res.data
          );
          this.listDeploy.row[2].connector.props.items = this.itemsGroup.column;
          this.listDeploy.row[3].connector.props.items =
            this.itemsGroup.comptype;
          this.listDeploy.row[4].connector.props.items =
            this.itemsGroup.contentseq;
        });
    },
    beforeSave(form) {
      // 给表单对象追加一个客户端类型属性，并赋值一个表示移动端的value
      form.mtype = "M";
    },
    /**
     * @param {*} event 上下按钮类型：upMove表示方向上，downMove表示方向下
     * @param {*} item 当前点击按钮所在的数据项
     */
    otherTableOperate(event, item) {
      this.axios
        .post(this.api.changeMenuSeq, {
          lup: event === "upMove" ? true : false,
          sqlid: item.sqlid,
        })
        .then((res) => {
          if (res.code === this.staticVal.Code.Success) {
            this.snackbar.success("更新排序成功");
            this.list();
          }
        });
    },
  },
  mounted() {},
};
</script>

<style>
</style>
